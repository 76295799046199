import { AnalyticsManager } from "@/outscal-commons-frontend/Managers";
import UTMManager from "./UTMManager";
import BasePageManager from "./PageServices/BasePageManager";
import JobPlugsManager from "./JobPlugsManager";
import UserDetailsManager from "./UserDetailsManager";
import UserFlowManager from "./UserFlowService/UserFlowManager";
import ClientAppManagerInterface from "./ClientAppManagerInterface";
import OutscalCustomPlugin from "@/outscal-commons-frontend/Managers/Analytics/AnalyticsPlugin";
import ClientBotTrackingService from "./AnalyticsUtils/ClientBotTrackingService";
import AssessmentsManager from "./AssessmentsManager";
import JobAlertsManager from "./JobAlertsManager";
import { UserCourseStatusEnum } from "@/outscal-commons-frontend/Enums";
import { FLOWTYPES } from "./UserFlowService/UserFlowService.types";
import AuthDiscordPageManager from "./PageServices/AuthDiscordPageManager";
import AuthGooglePageManager from "./PageServices/AuthGooglePageManager";
import FtuePageManager from "./PageServices/FtuePageManager";
ClientBotTrackingService.getInstance();

type FeatureFlags = {
  forcePhoneNumberOnAdsJW: boolean;
  showJobResourcesPlug: boolean;
};

class ClientAppManager implements ClientAppManagerInterface {
  private isInitialized: boolean = false;
  public static instance: ClientAppManager;
  public featureFlags: FeatureFlags;
  private pageLoadFlowTrigerred: boolean = false;
  private inIframe: Boolean;

  public pageManager: BasePageManager<any, ClientAppManager> | null = null;
  public jobPlugsManager: JobPlugsManager;
  public userDetailsManager: UserDetailsManager;
  public assessmentsManager: AssessmentsManager;
  public jobAlertsManager: JobAlertsManager;
  public userFlowManager: UserFlowManager;
  public timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  private constructor() {
    this.inIframe = typeof window !== "undefined" && window.self !== window.top;
    this.jobPlugsManager = new JobPlugsManager();
    this.assessmentsManager = new AssessmentsManager();
    this.jobAlertsManager = new JobAlertsManager();
    this.userDetailsManager = new UserDetailsManager(this);
    this.userFlowManager = new UserFlowManager();
  }

  public get inIFrame(): Boolean {
    return this.inIframe;
  }

  public static getInstance(): void {
    if (!this.instance) {
      this.instance = new this();
    }
  }

  public checkTriggerFlowOnDetailsInitialised() {
    if (
      this.pageManager instanceof AuthDiscordPageManager ||
      this.pageManager instanceof AuthGooglePageManager ||
      this.pageManager instanceof FtuePageManager
    ) {
      return;
    }
    const existingFlow = sessionStorage.getItem("existing-flow");
    if (!existingFlow && !this.pageLoadFlowTrigerred) {
      this.pageLoadFlowTrigerred = true;
      const userCourseStatuses =
        this.userDetailsManager?.user?.userCourseStatuses;

      if (
        userCourseStatuses?.find(
          (status) =>
            status.current_status?.status ==
            UserCourseStatusEnum.getValue("waitlisted")
        )
      ) {
        this.userFlowManager?.checkAndTriggerFlow(FLOWTYPES.RETURNING_JW_USER, {
          waitlistedCourses: userCourseStatuses.map(
            (status) => status.course.slug
          ),
        });
      }
    }
  }

  public onInitialise = () => {
    if (typeof sessionStorage == "undefined") {
      return;
    }
    const existingFlow = sessionStorage.getItem("existing-flow");
    let flowData = JSON.parse(sessionStorage.getItem("flow-data") || "{}");

    if (existingFlow) {
      this.pageLoadFlowTrigerred = true;
      this.userFlowManager.initialiseExistingFlow(Number(existingFlow), {
        ...flowData,
      });
    }
  };

  public initialize(): void {
    if (this.isInitialized) {
      return;
    }
    AnalyticsManager.setOutscalAnalyticsPlugin(OutscalCustomPlugin);
    this.isInitialized = this.initializeServices();
    this.onInitialise();
  }

  private initializeServices(): boolean {
    if (typeof window !== "undefined") {
      UTMManager.getInstance();
      return true;
    }
    return this.isInitialized;
  }

  public appMounted(): void {
    AnalyticsManager.checkAuth();
  }

  public setFeatureFlags(featureFlags: FeatureFlags): void {
    this.featureFlags = featureFlags;
  }

  public setPageManager(pageManager) {
    this.pageManager = pageManager;
    this.pageManager.setClientAppManager(this);
  }

  public reset(): void {
    this.isInitialized = false;
  }
}

export default ClientAppManager;
