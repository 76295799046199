import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { highlightedNavbarItems } from "./GeneralNavBar/mobileNavbar.model";
import { isContentCoursePage } from "@/Logic/ServerLogic/PageDataControllers/CourseDetails/CourseDetailsPageData.model";
const AdsNavbarController = dynamic(() =>
  import("./AdsNavbar/AdsNavbar.controller")
);
const MobileNavBarController = dynamic(() =>
  import("./GeneralNavBar/mobileNavbar.controller")
);
const MobileNavbarBannerService = dynamic(() =>
  import("./MobileNavbarBanner/MobileNavbarBanner.service")
);

const MobileNavbarService = () => {
  const router = useRouter();

  const PageNavBars = {
    "/job-listing/[jobSlug]": null,
    "/welcome/[ftue]": null,
    "/form/[formSlug]": null,
    "/course/[courseSlug]": null,
    "/social/[socialSlug]": null,
    "/waitlist-confirmation": null,
    "/course-book-call": null,
    "/job-apply-form/[jobSlug]": null,
    "/course/[courseSlug]/[moduleSlug]/[chapterSlug]/[materialSlug]": null,
    "/courses": MobileNavBarController,
    "/scholarships": MobileNavBarController,
    "/course-listing/gamedev": null,
    "/course-listing/gamedev-it-yogesh": null,
    "/course-listing/gamedev-it-soumya": null,
    "/course-listing/gamedev-qa": null,
    "/course-listing/gamedev-qa-pranay": null,
    "/blog/[blogSlug]": null,
    "/[username]": MobileNavBarController,
    "/community/full-stack-game-development-ads": AdsNavbarController,
    "/community/india-subscription": null,
    "/us/community/international-subscription": null,
    "/community/subscriptions": null,
    "/course/full-stack-game-development_ads": null,
    "/course/full-stack-game-development_unity_ads": null,
    "/us/course/full-stack-game-development_ads": null,
    "/us/course/full-stack-game-development_unity_ads": null,
  };

  let basePath = router.basePath;
  let pathname = router.pathname;
  let asPath =
    typeof window !== "undefined" ? window?.location.pathname : router.asPath;
  let NavBar;
  if (PageNavBars.hasOwnProperty(basePath)) {
    NavBar = PageNavBars[basePath];
  } else if (PageNavBars.hasOwnProperty(pathname)) {
    NavBar = PageNavBars[pathname];
  } else if (PageNavBars.hasOwnProperty(asPath)) {
    NavBar = PageNavBars[asPath];
  } else {
    NavBar = MobileNavBarController;
  }

  let highlightedItems =
    highlightedNavbarItems[router.basePath || router.pathname];

  return (
    <>
      {isContentCoursePage(router.asPath) ? null : (
        <>
          {NavBar ? (
            <>
              <MobileNavbarBannerService />
              <NavBar highlightedItems={highlightedItems} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default MobileNavbarService;
