import {
  Div,
  PopupTemplate,
  RowDiv,
  ColDiv,
} from "@/outscal-commons-frontend/Styled";
import React from "react";
import {
  CTA,
  HTMLMessage,
  Header,
  Message,
  Root,
  Title,
  MessageImage,
  CloseIcon,
  CTAWrapper,
} from "./MessagePopup.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const MessagePopupView = (
  {
  title,
  hidePopups,
  message,
  action,
  ctaText,
  image,
  imageSize,
  htmlMessage,
  messageColor,
  messageFontSize,
  width,
  padding,
  maxWidth,
  dismissable = true,
  analyticsText,
  metaText,
  onPopupClose,
  onCloseByUser,
  cta2Text,
  cta2Action,
  customComponent,
}
) => {
  return (
    <PopupTemplate
      setShowPopUp={
        dismissable
          ? () => {
              hidePopups();
              onPopupClose();
              onCloseByUser();
            }
          : () => {}
      }
      maxWidth={maxWidth}
      width={width}
      height="fit-content"
      bgColor="white"
      overflow="hidden"
      id={`${analyticsText ? `${analyticsText}_` : ""}message_popup`}
    >
      <Div style={{ position: "relative" }}>
        <Root id="message_popup" padding={padding}>
          {dismissable && (
            <RowDiv>
              <CloseIcon
                onClick={() => {
                  hidePopups();
                  onCloseByUser();
                  onPopupClose();
                }}
                data-analytics={[
                  UIElements.BUTTON,
                  `message_close_${analyticsText ? analyticsText : ""}`,
                ]}
              />
            </RowDiv>
          )}
          <ColDiv gap="4px">
            {image && (
              <MessageImage
                height={imageSize}
                width={imageSize}
                style={{ objectFit: "contain" }}
                src={image}
              />
            )}
            {title && (
              <Header>
                <Title>{title}</Title>
              </Header>
            )}
          </ColDiv>
          {message && (
            <Message
              messageColor={messageColor}
              messageFontSize={messageFontSize}
            >
              {message}
            </Message>
          )}
          {htmlMessage && (
            <HTMLMessage
              dangerouslySetInnerHTML={{ __html: htmlMessage }}
            ></HTMLMessage>
          )}
          {customComponent}
          <CTAWrapper gap="16px" justifyContent="center" alignItems="center">
            {cta2Text && (
              <CTA
                onClick={() => {
                  cta2Action();
                  onPopupClose();
                }}
                data-analytics={[
                  UIElements.BUTTON,
                  analyticsText ? `${analyticsText}-cta2` : "message-popup",
                ]}
              >
                {cta2Text}
              </CTA>
            )}
            {ctaText && (
              <CTA
                onClick={() => {
                  action();
                  onPopupClose();
                }}
                data-analytics={[
                  UIElements.BUTTON,
                  analyticsText ? `${analyticsText}-cta` : "message-popup",
                ]}
              >
                {ctaText}
              </CTA>
            )}
          </CTAWrapper>
          {metaText && (
            <Message messageColor={"black"} messageFontSize={"sm"}>
              {metaText}
            </Message>
          )}
        </Root>
      </Div>
    </PopupTemplate>
  );
};

export default MessagePopupView;
