import BaseEnum from "./BaseEnum";

const UserCourseStatusEnum = new BaseEnum(
  [
    "waitlisted",
    "enrolled",
    "access-granted",
    "started",
    "admin-accepted",
    "admin-rejected",
    "enrolled-pro",
    "access-granted-pro",
    "started-pro",
    "access-removed",
  ],
  [
    "Waitlisted",
    "Enrolled",
    "Access Granted",
    "Started",
    "Admin Accepted",
    "Admin Rejected",
    "Enrolled PRO",
    "Access Granted PRO",
    "Started PRO",
    "Access Removed",
  ]
);

export default UserCourseStatusEnum;
